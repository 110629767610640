/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { Locales } from '../language/Localize';
import getEnv from '../utils/environment';
import { GROUP_LAYERS, LAYERS, LEAFLET_GROUP_LAYERS } from './Enums';

export const ANIMATION_DURATION = '.8s';

export const LAYER_NAMES: any = {
  [GROUP_LAYERS.Sites]: Locales.Sites,
  [LAYERS.SiteFavorite]: Locales.FavoriteSites,
  [GROUP_LAYERS.Zones]: Locales.Zone,
  [GROUP_LAYERS.Grid]: Locales.Grid,
  [GROUP_LAYERS.Concentration]: Locales.Concentration,
  [GROUP_LAYERS.Hotspot]: Locales.Hotspot,
  [GROUP_LAYERS.Flares]: Locales.Flares,
  [GROUP_LAYERS.Landmarks]: Locales.Landmarks,
  [GROUP_LAYERS.Predictions]: Locales.Predictions,
  [GROUP_LAYERS.Plumes]: Locales.YourSitesEmissions,
  [GROUP_LAYERS.GhgPlumes]: Locales.GHGSatSatellites,
  [GROUP_LAYERS.GhgPlumesAV]: Locales.GHGSatAircrafts,
  [GROUP_LAYERS.ThirdPartyPlumes]: Locales.ThirdPartySatellites,
  [GROUP_LAYERS.PlumesZone]: Locales.ZoneEmissions,
  [GROUP_LAYERS.GhgPlumesZone]: Locales.GHGSatSatellites,
  [GROUP_LAYERS.ThirdPartyPlumesZone]: Locales.ThirdPartySatellites,
  [GROUP_LAYERS.Observations]: Locales.Observations,
  [LAYERS.Catalog]: Locales.Catalog,
  [LAYERS.MyBookmarks]: Locales.MyBookmarks,
};

export const LEAFLET_LAYER_NAMES: any = {
  [LEAFLET_GROUP_LAYERS.Basemap]: Locales.basemap,
  [LEAFLET_GROUP_LAYERS.Concentration]: Locales.Concentration,
};

export function getBucketBaseUrl(): string {
  if (getEnv() === 'local') {
    return './aws/';
  } else {
    return './';
  }
}

export const BUCKET_BASE_URL = getBucketBaseUrl();
export const BASE_NEWS_URL = BUCKET_BASE_URL + 'news/';
export const BASE_PLUMES_URL = BUCKET_BASE_URL + 'plumes/';
export const CONCENTRATION_MANIFEST = BUCKET_BASE_URL + 'concentrations/v1/manifest.json';

// Note that we grab the manifest in plumes because this is a record of all of the news articles
// that have a generated raster plume
export const NEWS_MANIFEST = BASE_PLUMES_URL + 'plumes_manifest.json';

export const MAX_ZOOM_LVL = 17;
export const MIN_ZOOM_LVL = 3;
export const DEFAULT_ZOOM_LVL = 3;
export const MAX_LAT = 90;
export const MIN_LAT = -90;
export const DEFAULT_LAT = 30;
export const MAX_LNG = 180;

export const MIN_LNG = -180;
export const DEFAULT_LNG = 0;

export const MIN_PLUME_RASTER_ZOOM_LEVEL = 10;

export enum LANG {
  en = 'en',
  fr = 'fr',
}

export const PRIVACY_POLICY_URL = 'https://www.ghgsat.com/en/privacy-policy/';
export const AWS_COOKIE_PREFIX = 'spectra-basic';

export const AWS_COOKIE_DOMAIN =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_PROD_COOKIE_DOMAIN
    : process.env.REACT_APP_DEV_COOKIE_DOMAIN;
