/**
 * /*
 * Copyright 2021 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 * @format
 */

// ES6 module syntax
import LocalizedStrings from 'react-localization';

/* USAGE
  import { Locales } from '../core/Localize';
  Locales.goBackToAllSite
*/

export const Locales: any = new LocalizedStrings({
  en: {
    AddingLayer: 'Adding Layer',
    AddALayer: 'Add a Layer',
    AddNewBookmark: 'Add New Bookmark',
    AddNewUser: 'Add new user',
    AlertingRadius: 'Alerting Radius',
    Alerts: 'Alerts',
    All: 'All',
    AllTime: 'All time',
    Analytics: 'Analytics',
    AnalyticsFrom: 'Analytics From',
    Agriculture: 'Agriculture',
    AircraftSensor: 'Aircraft Sensor',
    AskForObservation: 'Ask For Observation',
    AssetType: 'Asset type',
    AvailableObservation: 'Site Observation',
    AvailableOn: 'Available On',
    CatalogObservation: 'Catalog Observation',
    BackToLogin: 'Back to Log In',
    BuyableObservation: 'Buyable Observation',
    Basemap: 'Basemap',
    BookmarkChangesSuccessful: 'Bookmark changes successful',
    BookmarksSavedSuccessfully: 'Bookmark changes were successfully saved',
    Cancel: 'Cancel',
    cart: 'Cart',
    Catalog: 'Catalog',
    CatalogOffering: 'Catalog Offering',
    Center: 'Center',
    ChangeMyPassword: 'Change my password',
    Close: 'Close',
    CollapseAll: 'Collapse all cards',
    Complete: 'Complete',
    Concentration: 'Concentration',
    Confidence: 'Confidence',
    ConfidencePrediction: 'Confidence Prediction',
    ConfirmNewPassword: 'Confirm new password:',
    ConfirmYourNewPassword: 'Confirm your new password',
    contained: 'contained',
    Coordinates: 'Coordinates',
    Coord: {
      N: 'N',
      E: 'E',
      S: 'S',
      W: 'W',
    },
    ConfirmAction: {
      ConfirmDeleteUser: (name: string) => `Do you want to delete the user account for ${name}?`,
      ConfrimResendInvite: (name: string) => `Do you want to resend the invitation to ${name}?`,
      ConfirmExitMyBookmarksModal: `Changes made since the last save will be lost. Do you want to proceed?`,
    },
    ContactGHGSatSalesForAZone: 'Contact your GHGSat sales representative to purchase the data for a zone.',
    ContactGHGSatSalesForThisObs: 'Contact your GHGSat sales representative to purchase this observation.',
    ContactGHGSatSalesForThisZone: 'Contact your GHGSat sales representative to purchase the data for this zone.',
    ContactGHGSatSalesForAllZones: 'Contact your GHGSat sales representative to purchase zone data.',
    CopyLink: 'Copy Link',
    Country: 'Country',
    CreateAnAccount: 'Create an Account',
    CreateAccount: 'Create Account',
    CreateAccountConfirmation: 'Account Confirmation',
    CreateAccountSuccess: (email: string) =>
      `We have sent an email to: ${email}. In the email you will find a link that when you click will bring you back to the site to log in.`,
    CreateAccountCheckSpam: 'Please check your junk and spam folder before contacting our support team.',
    CreatePassword: 'Create Password',
    CreatePasswordProblem: 'Error - The password could not be created.',
    CreatePasswordSuccess: 'Password Created',
    createPasswordSubtitle: 'Please enter a password',
    'create-password': 'create-password',
    CsvFilePrefix: {
      EmissionsList: 'emissions-list',
      ObservationList: 'observation-list',
      SiteList: 'site-list',
      SiteDetails: 'site-details',
      ZoneEmissions: 'zones-emissions',
    },
    currentlyDisplaying: 'Currently Displaying',
    DataDisplay: 'Data Display',
    dataLayers: 'Data layers',
    DataAvailableFrom: 'Data available from',
    Date: 'Date',
    DateOfObservation: 'Date of Observation',
    days: 'Days',
    'forgot-password': 'forgot-password',
    exportAsCsv: 'Export as .csv file',
    DeleteView: 'Delete View',
    DeleteAllViews: 'Delete All Views',
    DemoData: 'Demo Data',
    Disconnect: 'Disconnect',
    Display: 'Display',
    DisplaySiteOnMap: 'Display Site On Map',
    DistanceUnit: 'Distance Unit',
    DownloadCaps: 'DOWNLOAD',
    DownloadFiles: 'Download Files',
    DownloadListView: 'Download List View',
    DownloadObservations: 'Download Observations',
    DownloadAllObservations: 'Download All Observations',
    DownloadReport: 'Download Report',
    DownloadStarted: 'Download started',
    DownloadingObservations: 'Downloading Observations',
    Email: 'Email',
    EmailAddress: 'Email address',
    EmailAddressCap: 'Email Address',
    EmissionsDetected: 'Emissions Detected',
    Emission: 'Emission',
    EmissionID: 'Emission ID',
    Emissions: 'Emissions',
    EmissionsList: 'Emissions List',
    EmissionRate: 'Emission Rate',
    EmittingSites: 'Emitting Sites',
    End: 'End',
    EnterLayerUrl: 'Enter the layer url',
    Error: 'Error',
    Errors: {
      BookmarkChangesUnsuccessful: 'Bookmark changes unsuccessful',
      BookmarkChangesNotSaved: 'Bookmark changes were not saved',
      CouldNotLoadConcentration: 'Could not load concentration data',
      CouldNotLoadEmissionsList: 'Could not load emissions data',
      CouldNotLoadEmissionOnMap: 'Could not load emission data on map',
      CouldNotLoadPremiumSatEmissionsList: 'Could not load premium offering satellite emissions data',
      CouldNotLoadGlobalSurveyList: 'Could not load global survey data',
      CouldNotLoadObservationsList: 'Could not load observations data',
      CouldNotLoadObservationOnMap: 'Could not load observation data on map',
      CouldNotLoadSitesList: 'Could not load sites data',
      CannotFindMap: 'There was some error with the map view',
      InvalidEmail: 'Invalid Email',
      PasswordMatch: 'Your passwords do not match',
      PasswordIncorrect: 'Incorrect Password',
      PasswordComplexity: 'Your password is not complex enough',
      TOSError: 'Could not accept Terms of Service',
    },
    ErrorRate: 'Error Rate',
    ErrorLoadingDetails: 'Error loading details...',
    ErrorLoadingLayer: 'Error loading layer...',
    ErrorLoadingNotifications: 'Error loading notifications...',
    entries: 'entries',
    Esri: 'Esri',
    ExpandAll: 'Expand all cards',
    FAQ: {
      Title: 'Frequently Asked Questions',
      SpectraBasicByGHGSat: 'Spectra Basic By GHGSat',
      SubHeader: 'A free-to-use tool to visualise a high-resolution map of methane around the world',
      HowToUseSpectraBasicQ: 'How to use Spectra Basic?',
      HowToUseSpectraBasicA:
        'Once you’ve logged into Spectra Basic for the first time, you will be able to navigate around the map to see the spatial and temporal movement of methane in the atmosphere over land. A time slider function in the Timeline panel at the bottom of the screen lets you explore how the concentrations of methane change over time, with the facility to track back in time.',
      WhatCanASpectraBasicUserAccessQ: 'What can a Spectra Basic user access?',
      WhatCanASpectraBasicUserAccessA:
        'As a Spectra Basic user, you can access the topographic map and the methane concentration layer along with the News. You can also share your current map view with other Spectra Basic users via email. The functions that are disabled, like the list view and the emissions layers are accessible to Spectra Premium.',
      WhatSpectraBasicCanShowYouQ: 'What Spectra Basic can show you?',
      WhatSpectraBasicCanShowYouA:
        'What you can see on the map are concentrations of methane in the atmosphere from both natural and man-made sources combined. You can see how methane moves through our atmosphere and also, by using the time slider, you can see the differences in concentration levels over time.',
      CanIShareTheMapQ: 'Can I share the map?',
      CanIShareTheMapA:
        'Yes. You can easily share a specific area of interest on the map in time. Select a location and a date that you want to share. Click on the button located in the bottom right cornet of the map, it will generate a link that you can copy and share with any Spectra Basic user.',
      WhatSpectraBasicCantTellYouQ: "What Spectra Basic can't tell you?",
      WhatSpectraBasicCantTellYouA1:
        'Spectra Basic provides a high-resolution map of methane concentrations in our atmosphere, made available for free. By making this visualization tool freely available, we want to raise awareness of, and support discussion about, this potent greenhouse gas. Whilst we can see methane concentrations, it is not possible to use the map to identify specific sources of methane. This is because the data powering the map is based on rolling weekly averages and we also need to take into account that winds move methane through our atmosphere.',
      WhatSpectraBasicCantTellYouA2:
        'Spectra Basic concentration map is not intended for scientific research. We strongly encourage scientists to refer to technical documentation for the underlying data sources, notably GHGSat satellites',
      WhatSpectraBasicCantTellYouA3: 'and ESA’s Sentinel-5P/TROPOMI satellite', //(http://www.tropomi.eu/sites/default/files/files/publicSentinel-5P-TROPOMI-ATBD-Methane-retrieval.pdf).',
      WhatDoesConcentrationsMeanQ: "What does 'concentrations' mean?",
      WhatDoesConcentrationsMeanA:
        '‘Concentrations’ refer to the amount of gas present in a given volume in the atmosphere, whereas an ‘emission’ refers to the rate at which gas is released into the atmosphere.',
      HowDoYouCalculateTheConcentrationLevelsQ: 'How do you calculate the concentration levels?',
      HowDoYouCalculateTheConcentrationLevelsA:
        'Spectra Basic shows methane concentrations on a grid averaging 2km x 2km over land and is based on weekly averages.',
      WhatDoesTheColourScaleMeanQ: 'What does the colour scale mean?',
      WhatDoesTheColourScaleMeanA:
        'Concentrations of methane are expressed in ‘parts per billion’ (ppb), in other words, number of methane molecules per billion air molecules. The colour scale used on the map is a graphical representation of that concentration.',
      ConcernQ: 'It looks like there is a high concentration of methane over my region - should I be concerned?',
      ConcernA:
        'Spectra Basic shows concentrations that result from both the emission (release in the area) and transport (movement in the atmosphere due to winds and chemical processes) of methane. Methane is produced by natural processes, such as the decay of vegetation in wetlands but a significant proportion ( about 60%) is attributable to human activity, with energy production, agriculture, and waste management (landfills and dumpsites) all being major sources. Seasonality and topography are also factors that can explain variations in methane concentrations. As a result, a high concentration over a region is not a reason for concern, but rather the start of a broader discussion to better understand the underlying cause(s).',
      GreyOverLandQ: 'Why can I see patches of grey over land?',
      GreyOverLandA:
        'Some areas of the world do not have reliable measurements, particularly in mountainous areas and areas with frequent cloud cover.',
      OceansQ: 'Why can’t I see methane concentrations over oceans?',
      OceansA:
        'Water absorbs sunlight rather than reflect it back to space as land does. As a result, measurements with most satellites (such as GHGSat satellites and Sentinel-5P/TROPOMI) operating in shortwave infrared (SWIR) are not always reliable over water. Other models predict the transport of methane over water; Spectra Basic is designed to show average concentrations based on measurements rather than to make predictions.',
      DataQ: 'Where does the data behind Spectra Basic come from?',
      DataA:
        'GHGSat’s Spectra Basic concentration map is based on data from the TROPOspheric Monitoring Instrument (TROPOMI) which is the satellite instrument on board the Copernicus Sentinel-5 Precursor satellite. The Sentinel-5 Precursor (S5P) is the first of the atmospheric composition Sentinels, launched on 13 October 2017.',
      AccessDataQ: 'Can I access data to find out potential sources of methane emissions?',
      AccessDataA:
        'Spectra Basic does not indicate potential sources of methane. For source attribution, GHGSat also offers a range of commercial services available through Spectra Premium. These services support industry and Governments to identify and mitigate methane emissions to meet global warming and climate change targets. It includes hotspot detection, predictive analysis and 25m resolution imagery to identify sources. You can find out more about these commercial services at',
      UnderlyingDataQ: 'Will GHGSat provide the underlying data to Spectra Basic?',
      UnderlyingDataA1:
        'GHGSat can make the underlying data available to qualifying users for non-commercial purposes, with priority given to academic institutions. Please subscribe to our newsletter or message',
      UnderlyingDataA2: 'to be contacted when the data becomes available.',
      CommercialUseQ: 'Can GHGSat provide the underlying data for commercial use?',
      CommercialUseA1: 'Yes. Please contact',
      CommercialUseA2: 'with specific requests.',
      WhyHaveYouBuiltSpectraBasicQ: 'Why have you built Spectra Basic?',
      WhyHaveYouBuiltSpectraBasicA:
        'GHGSat announced the map at the World Economic Forum (WEF) Annual Meeting in Davos in January 2020, where GHGSat was invited to participate as a WEF Technology Pioneer. The map was promised as a contribution to COP26 discussions, an opportunity for GHGSat to share its greenhouse gas expertise and data for free to help tackle climate change.',
    },
    Favorite: 'Favorite',
    FavoriteSites: 'Favorite Sites',
    favorites: {
      AddToFavorites: 'Add to Favorite Sites',
      AddedToFavorites: 'Added to Favorite Sites',
      RemoveFromFavorites: 'Delete from Favorites',
      RemovedFromFavorites: 'Deleted from Favorite Sites',
    },
    Flare: 'Flare',
    Flares: 'Flares',
    FlaresDetected: 'Flares Detected',
    FilterByEmissionID: 'Filter By Emission ID',
    FilterByID: 'Filter By ID',
    FilterByObservationID: 'Filter By Observation ID',
    FilterBySensor: 'Filter By Sensor',
    FilterBySiteName: 'Filter By Site Name',
    FilterByZone: 'Filter By Zone',
    FindPlaceCityRegion: 'Find Place, City, Region',
    FirstName: 'First Name',
    Forecast: 'Forecast',
    ForgotPassword: 'Forgot Password',
    ForgotPasswordInstruction: 'Enter your email address below and we will send you a link to reset your password.',
    ForgotPasswordSuccess: (email: string) =>
      `We have sent an email to ${email}. If you don’t receive an email, please check that you entered a valid Spectra account and resubmit.`,
    ForgotPasswordFail: 'Error - Unable to initiate forgot password',
    Found: 'Found',
    From: 'From',
    FromTo: 'From / To',
    FromZones: 'From Zones',
    FromYourSites: 'From Your Sites',
    GetStartedWithSpectra: 'Get Started with SPECTRA',
    ghgSat: 'GHGSat',
    GHGSatSensor: 'GHGSat Sensor',
    ghgSatUrl: 'https://www.ghgsat.com/',
    ghgSatContactEmail: 'orders@ghgsat.com',
    GHGSatAircrafts: 'GHGSat Aircrafts',
    GHGSatSatellites: 'GHGSat Satellites',
    GlobalError: 'An error occured. Please try again.',
    goBackToAllSite: 'Go back to all sites',
    GoBack: 'Go Back',
    GoToLogin: 'Go To Login',
    GoToMyProfile: 'Go To My Profile',
    GoToSite: 'Go To Site',
    GoToThisDisplay: 'Go To This Display',
    GoToThisObservation: 'Go To This Observation',
    Grid: 'Grid',
    GridCell: 'Grid Cell',
    Hello: 'Hello',
    Help: 'Help',
    hi: 'Hi',
    High: 'High',
    Holdbacks: 'Holdbacks',
    home: 'Home',
    Hotspot: 'Hotspot',
    Hotspots: 'Hotspots',
    HotspotDetected: 'Hotspot Detected',
    IAgree: 'I Agree',
    ID: 'ID',
    Id_Lower: 'Id',
    IForgotMyPassword: 'I Forgot my Password',
    insights: 'Insights',
    InvalidInviteLink: {
      Title: 'Invitation is expired or invalid',
      Subtitle:
        'This invitation link is expired or invalid. Contact your organization administrator or your sales representative to request a new link.',
    },
    InvalidPasswordResetLink: {
      Title: 'Password reset link is expired or invalid',
      Subtitle:
        'This password reset link is expired or invalid. Contact your organization administrator or your sales representative to request a new link.',
    },
    InvisibleAtCurrentScale: 'Invisible at current scale',
    InZone: 'In Zone',
    kg: 'kg',
    kt: 'kt',
    kgPerH: 'kg/h',
    tabHeaderOne: 'Site List',
    tabHeaderTwo: 'Observation list',
    tEqualKg: '1 t = 1,000 kg',
    tPerH: 't/h',
    LandmarkAlerting: 'Landmark Alerting',
    Landmark: 'Landmark',
    Landmarks: 'Landmarks',
    Last: 'Last',
    Last6Months: 'Last 6 months',
    Last3Months: 'Last 3 months',
    LastMonth: 'Last month',
    LastName: 'Last Name',
    LastWeek: 'Last week',
    LastUpdate: 'Last Update',
    LastUpdateOn: 'Last Update on',
    Latitude: 'Latitude',
    LatitudeError: 'Latitude must fall between -90 and 90.',
    Leaflet: 'Leaftlet',
    Legend: 'Legend',
    LinkCopied: 'Link Copied',
    Longitude: 'Longitude',
    login: 'login',
    Login: 'Login',
    LogIn: 'Log In',
    LoginCopy: {
      Title: 'Changing the way we see greenhouse gas data and reduce emissions',
      PreTitle: 'Emissions Data and Analytics with SPECTRA',
      Desc: 'Access intelligence to inform business decisions, act on methane emissions and reach your decarbonisation targets. SPECTRA delivers CH4 monitoring services and insights combining GHGSat high-resolution data with industry and satellite datasets.',
      KnowMore: 'You would like to know more?',
      ContactUs: 'Contact Us',
      ProblemLoggingIn: 'Problem logging in? Contact our support team',
    },
    LoginError: 'Error - login unsuccessful',
    Logout: 'Logout',
    logout: 'logout',
    LogOut: 'Log Out',
    LogginIn: 'Loggin In',
    LoggingOut: 'Logging out',
    LoadingSites: 'Loading Sites',
    LoadingObservations: 'Loading Observations',
    LoadingEmissions: 'Loading Emissions',
    Loading: 'Loading',
    LongitudeError: 'Longitude must fall between -180 and 180.',
    Low: 'Low',
    ManageUsersAndRoles: 'Manage users and roles',
    map: 'map',
    maintenance: 'maintenance',
    Maintenance: {
      Title: 'SPECTRA is under maintenance',
      SubTitle: 'We will be back online at 4PM ET',
    },
    MapView: 'Map view',
    mapPage: 'Map Page',
    Maybe: 'Maybe',
    Methane: 'Methane',
    MethaneEmissions: 'Total Methane Emissions (last 6 months)',
    MethanesTimelines: 'Methane’s timelines',
    Mining: 'Mining',
    MeasurementsFrom: 'Measurements From',
    Measurements: 'Measurements',
    MeasurementsAdjusted: 'Measurements have been automatically adjusted',
    Monitored: 'Monitored',
    Month: 'Month',
    MonthlyEmissionsFromZones: 'Monthly Emissions From Zones',
    MonthlyEmissionsFromSites: 'Monthly Emissions From Your Sites',
    Months: 'Months',
    MoreInfo: 'More Info',
    MyBookmarks: 'My Bookmarks',
    MyBookmarkActions: {
      MyBookmarkSaved: 'My Bookmark saved',
      MyBookmarkSaveUnsuccesful: 'My Bookmark save unsuccesful',
    },
    MyDisplays: {
      MyDisplays: 'My Displays',
      ChooseAsDefault: 'Set as Default',
      CreateNewDisplay: 'Create New Display',
      MyDefaultDisplay: ' My Default Display',
      NoDisplays: 'You have no displays.',
      save: {
        NewDisplayName: 'New display name',
        title: 'Save this display',
        fieldLabel: 'Name of this display',
        placeholder: 'My awesome display',
        confirmation:
          'You are about to save this display (map extent, basemap, etc.). ' +
          'This display will be available in your personal menu for later use loem ipsum dolor sit amet',
        cta: 'Save display',
        checkBoxLabel: 'Choose as main display (will be displayed each time you log in the platform).',
        Success: 'Display Saved',
      },
      edit: {
        title: 'Edit display information',
        fieldLabel: 'Display name',
        placeholder: 'North America - Critical sites',
        confirmation: 'confirm',
        cta: 'Save CHANGES',
      },
      delete: {
        title: 'Delete display',
        cta: 'YES, Delete display',
        confirmation: 'You are about to delete “North America - Critical sites”. Are you sure your want to proceed?',
        Success: 'Display Deleted',
      },
    },
    MyLayers: 'My layers',
    MyProfile: 'My Profile',
    MyProfileActions: {
      MyProfileSaved: 'My Profile saved',
      MyProfileSaveUnsuccessful: 'My Profile save unsuccessful',
      PasswordSaved: 'Password saved',
      PasswordSaveUnsuccessful: 'Password save unsuccessful',
    },
    NA: 'N/A',
    Name: 'Name',
    NearbySites: 'Nearby Sites',
    NewPassword: 'New Password',
    NewPasswordSaved: 'New password saved',
    NewPasswordSavedMessage: 'Please use this password to login to your account from now on.',
    News: 'News',
    NextUpdate: 'Next Update',
    NextWeek: 'Next Week',
    NeverObserved: 'Never Observed',
    No: 'No',
    NoData: 'No Data',
    NoEmissions: 'No emissions',
    NoNearbySites: 'No Nearby Sites',
    NoNotifications: 'No notifications',
    NoObservations: 'No observations',
    NoEmittingSiteInArea: 'No emitting sites in this area',
    NoEmissionsFound: 'No Emissions Found',
    NoObservationsFound: 'No Observations Found',
    NoSitesFound: 'No Sites Found',
    NoSitesOfInterestInArea: 'No sites of interest in this area',
    NoSitesVisibleAtThisScale: 'No sites visible at this scale',
    Notifications: 'Notifications',
    NotFound: {
      Title: '404',
      SubTitle: 'Oops! We can’t seem to find the page you’re looking for. From there Earth looks like a pale blue dot.',
    },
    NoReportsFound: 'No Reports Found',
    NoUsersFound: 'No Users Found',
    MaximumDownload: 'Maximum download of 150 observations',
    NumberOfObservations: 'Number of Observations',
    NumberOfSitesWhereEmissions: 'Number of sites where emissions have been detected in the last 7 days.',
    ObsId: 'Obs ID',
    observation: 'observation',
    Observation: 'Observation',
    ObservationID: 'Observation ID',
    ObservationDate: 'Observation Date',
    ObservationEmission: 'Emission',
    ObservationList: 'Observation List',
    ObservationRate: 'Rate',
    ObservationSensor: 'Sensor',
    Observations: 'Observations',
    observations: 'observations',
    ObservationsFrom: 'Observations From',
    ObservationFiles: 'Observation Files',
    ObservationConcentrationMap: 'Concentration Map',
    ObservationsInPreiod: 'Observations for this period',
    Observed: 'Observed',
    ObservedOn: 'Observed On',
    of: 'of',
    Off: 'Off',
    OilGasWellsSite: 'Oil & Gas - Wells Site',
    OilGasMidStream: ' Oil & Gas - Mid-Stream',
    Ok: 'Ok',
    On: 'On',
    OnlyFeatureLayersPermitted: 'Only feature layers are permitted.',
    OpenStreetMapsContributors: '© OpenStreetMap contributors',
    Organization: 'Organization',
    Other: 'Other',
    otherSites: 'Other sites',
    OtherZones: 'Other Zones',
    Password: 'Password',
    PasswordHasBeenReset: 'Your password has been reset',
    PossibleEmissons: 'Possible emissions',
    PasswordHints: [
      'Use 15 or more characters with a mix of lowercase and uppercase letters along with numbers.',
      'Do not use your email address.',
    ],
    PasswordResetProblem: 'Error - The password could not be reset.',
    Percent: '%',
    PhoneNumber: 'Phone Number',
    PleaseEnter: 'Please enter',
    PleaseSelect: 'Please select',
    poweredBy: 'Powered By',
    PowerGeneration: 'Power Generation',
    ppb: 'ppb',
    Prediction: 'Prediction',
    Predictions: 'Predictions',
    PredictionsConfidence: 'Prediction Confidence',
    PredictionRisk: 'Prediction Risk',
    PreviousWeek: 'Previous Week',
    Rate: 'Rate',
    sensorName: 'Sensor',
    RegisterUserFail: 'Error - Unable to register user',
    Remove: 'Remove',
    RemoveLayer: 'Remove Layer',
    Reports: 'Reports',
    RequestADemo: 'Request A Demo',
    RequestMoreInformation: 'Request More Information',
    ResendInvitation: 'Resend Invitation',
    ResetFilters: 'Reset Filters',
    ResetPassword: 'Reset Password',
    ResetPasswordSubtitle: 'Please enter a new password.',
    ResetPasswordSuccess: 'Your password has been reset.',
    Risk: 'Risk',
    RiskPrediction: 'Risk Prediction',
    Role: 'Role',
    Roles: {
      AdminUser: 'Admin User',
      ManagerUser: 'Manager User',
      AnalystUser: 'Analyst User',
    },
    Satellite: 'Satellite',
    SatelliteSensor: 'Satellite Sensor',
    SatelliteTarget: 'Satellite Target',
    SaveChanges: 'Save changes',
    SaveNewPassword: 'Save new password',
    ScheduledMaintenance: {
      // Scheduled Maintenance window is hiding if Item1 is empty
      // 2nd and 3rd section are hiding if Item2 and Item3 are empty
      Title: 'Scheduled Maintenance',
      Item1: '', // System Update: Thursday Sep 9th, 9am to 11am EDT
      Item2: '', // IT Maintenance: Saturday July 14th, 8am to 12pm EDT
      Item3: '', // Another upcoming maintenance
    },
    selected: 'selected',
    SendEmail: 'Send Email',
    Sensor: 'Sensor',
    Search: 'Search',
    SearchUsers: 'Search for name or email',
    SeeZone: 'See Zone',
    SelectFrom: 'Select from',
    SendResetLink: 'Send reset link',
    searchInputPlaceholder: 'Search for sites, date, risk ...',
    SearchForSiteName: 'Search for site name',
    SearchForNearbySite: 'Search for nearby sites',
    SelectAMonth: 'Select a Month',
    ShowSitesLayer: 'Show Sites layer',
    SinceLastObservation: 'Since Last Observation',
    Site: 'Site',
    SiteList: 'Site List',
    SiteTypes: {
      midstream: 'Mid-stream',
      well: 'Well',
      waste: 'Waste',
      'oil & gas - well': 'Well',
      mining: 'Mining',
      agriculture: 'Agriculture',
      'power Generation': 'Power Generation',
      Agriculture: 'Agriculture',
      Midstream: 'Mid-stream',
      Mine: 'Mine',
      Power: 'Power',
      Other: 'Other',
      Waste: 'Waste',
      Well: 'Well',
    },
    Sites: 'Sites',
    SitesContained: 'Site(s) Contained',
    SitesCovered: 'Site(s) Covered By This Observation',
    SitesClusters: 'Sites Clusters',
    SitesOfInterest: 'Sites of Interest',
    SitesLayerIsHidden: 'Sites layer is hidden',
    SiteObservation: 'Site Observation',
    Source: 'Source',
    SeeOnMap: 'See On Map',
    Settings: 'Settings',
    ShareView: {
      title: 'Share This View',
      overview: 'Map View',
      Share: 'Share',
      SendEmail: 'Send Email',
      EmailAddressOfTheRecipient: 'Email address of the recipient',
      Instruction:
        'Copy this link or click on the button below to share the map currently displayed. Only authorised users will be able to see this map in Spectra Basic.',
      Success: 'Map shared with',
      CopyLink: 'Copy Link',
    },
    Showing: 'Showing',
    ShowLayers: 'Show Layers',
    ShowOnMap: 'Show on Map',
    siteDetails: 'Site details',
    SiteName: 'Site Name',
    SiteType: 'Site Type',
    SpectraBasic: 'Spectra Basic',
    Start: 'Start',
    Status: 'Status',
    Success: 'Success',
    TableView: 'Table view',
    ListView: 'List view',
    t: 't',
    ObservationStatus: {
      Yes: 'Yes',
      No: 'No',
      Maybe: 'Maybe',
      Unknown: 'Unknown',
    },
    PrivacyPolicy: 'Privacy Policy',
    Table: {
      ViewFilters: 'Filters',
      ViewTimeline: 'Analytics Timeline',
    },
    TermsOfService: {
      FirstParagraph:
        'Pulse is a free-to-use map that helps you see concentrations of methane in the atmosphere worldwide. This tool is designed to raise awareness of, and support discussions about, this potent greenhouse gas.',
      SecondParagraph: 'By clicking on "I ACCEPT" below, I hereby confirm that I have read and accept the GHGSat ',
      And: 'and',
    },
    TermsofUse: 'Terms of Use',
    ThereIsNotEnoughDataInChart: 'There is not enough observation data to render this chart.',
    ThirdPartySatellites: 'Third Party Satellites',
    ThirdPartySensor: 'Third Party Sensor',
    ThisSiteDoesntExist: "This site doesn't exist",
    Threshold: 'Threshold',
    timelines: 'Timelines',
    to: 'to',
    To: 'To',
    Topographic: 'Topographic',
    TooltipsInfo: {
      concentration: 'The volume of methane in the air, measured in parts per billion (ppb)',
      confidence: 'Indicates the certainty of the predicted risk',
      demodata:
        'Switching the Demo Data ON will replace the displayed data by a demonstration dataset with Spectra Premium features enabled.',
      emissionObservedOn: 'Date when the emission was observed on',
      emissions: 'Indicates the amount of methane emitted',
      emissionDate: 'Date this emission was found by sensor',
      emissionObservationId: 'A unique identifier for the observation this emission was derived from',
      emissionSource: 'The source sensor where the emission was detected',
      errorRate: 'Error rate described as a percentage of rate',
      flares: 'Indicates whether a flame associated with devices used to burn natural gas has been detected',
      gridDate: 'Date range related to the analytics information being shown',
      hotspot: "A delta where the detected emission is significant when compared to GHGSat's methane data model",
      lastUpdated: 'Last time this location was observed by a GHGSat Sensor',
      latitudeExpect: 'Expect WGS-84 Latitude (with - sign for South hemisphere).',
      longitudeExpect: 'Expect WGS-84 Longitude (with - sign for West hemisphere).',
      observations: 'A satellite measurement taken at a specific site, hover for observation date and emission rate',
      observationDate: 'Date this observation was taken by sensor',
      predictionRisk: 'Indicates the risk of an emission',
      rate: 'Rate of emission',
      satelliteTarget: 'This is the target of a satellite when taking an observation that covers a larger area',
      sensorName: 'Offical name of sensor used',
      siteTypeAndLocation: 'Classification of this site and its location',
      sensorNameEmissions: 'Name of the sensor that observed this emission',
      siteType: 'Classification of this site',
      downloadPartialZones: (amount: number, total: number) =>
        `Download emission data by zone for ${amount} of the past ${total} months`,
      downloadTotalZones: (amount: number) => `Download emission data by zone for the past ${amount} months`,
    },
    TrendAnalysis: 'Trend Analysis',
    Type: 'Type',
    UpdatedTimelineFor: 'Updated timeline for',
    UpgradeNow: 'Upgrade Now',
    UpgradeForm: {
      UpgradeToSpectraPremium:
        'Upgrade to SPECTRA Premium to add real-time emission, catalog data and analytics for your target zones!',
      WithSpectraPremium: 'With Spectra Premium',
      Info1: 'See emissions from high-resolution and public satellites near your facilities.',
      Info2: 'Share data across your organization to ensure rapid reporting and action.',
      Info3: 'Provide an accurate view of methane emissions for impactful decision-making.',
      SubmissionUnsuccessful:
        'The form could not be submitted successfully, please email info.spectrabasic@ghgsat.com for more information.',
      SubmissionSuccessful: "Form was submitted successfully, we'll get back to you within 72 hours.",
      SubmissionEmailBlocked: 'Invalid email: only business emails are accepted.',
    },
    Undefined: 'Undefined',
    UnknownEmissions: 'Unknown Emission',
    UnsuccessfulLogin: 'Unsuccessful login',
    UnsuccessfulLogout: 'Unsuccessful logout',
    UserManagement: 'User Management',
    UserZones: 'User Zones',
    UserRole: 'User Role',
    UserRoles: {
      Analyst: 'Analyst',
      AnalystDesc: 'Can use SPECTRA and see data',
      Manager: 'Manager',
      ManagerDesc: 'Can do everything an Analyst can and use the cart (coming soon)',
      Admin: 'Admin',
      AdminDesc: 'Can do everything a Manager can and manage other users',
    },
    ViewAllZones: 'View All Zones',
    ViewDetails: 'Details',
    ViewEmission: 'View Emission',
    ViewHistory: 'View History',
    ViewSiteDetails: 'View Site Details',
    ViewThisSite: 'View This Site',
    ViewObservation: 'View Observation',
    WasteManagement: 'Waste Management',
    Week: 'Week',
    Welcome: 'Welcome to GHGSat',
    WhatInformationAreYouLookingFor: 'What information are you looking for?',
    WinningTheRaceTogether: 'Winning the race to zero-emissions, together.',
    Year: 'Year',
    Yes: 'Yes',
    YourEmail: 'Your email',
    YourPassword: 'Your password',
    YourCurrentPassword: 'Your current password',
    YourNewPassword: 'Your new password',
    YourPasswordsDoNotMatch: 'Your passwords do not match.',
    YourPasswordIsNotComplexEnough: 'Your password is not complex enough.',
    YourSites: 'Your Sites',
    YourSitesEmmiting: 'Emitting Sites',
    YourSitesEmissions: 'Your Site Emissions',
    YourSitesInZone: 'In Zone',
    YourZones: 'Your Zones',
    Zone: 'Zone',
    Zones: 'Zones',
    Zone_Zones: 'Zone(s)',
    ZoneEmissions: 'Zone Emissions',
    ZoomToSite: 'Zoom To Site',
    ZoomToEmission: 'Zoom To Emission',
  },
  fr: {
    favorites: {
      AddedToFavorites: 'Ajouté aux favoris',
      RemovedFromFavorites: 'Retiré des favoris',
    },
    CreatePasswordProblem: "Erreur - Le mot de passe n'a pas été créé.",
    ErrorLoadingLayer: 'Erreur de chargement...',
    ForgotPassword: 'Mot de passe oublié',
    'forgot-password': 'forgot-password',
    GlobalError: 'Une erreur est survenue. Svp. réessayer.',
    home: 'Accueil',
    InvalidInviteLink: {
      Title: 'Invitation expirée ou invalide',
      Subtitle:
        "Ce lien d'invitation a expiré ou n'est pas valide. Contactez l'administrateur de votre organisation ou votre représentant des ventes pour obtenir une nouvelle invitation.",
    },
    InvalidPasswordResetLink: {
      Title: 'Le lien pour la réinitialisation du mot de passe est invalide ou expiré',
      Subtitle:
        "Le lien pour la réinitialisation du mot de passe est invalide ou expiré. Contactez l'administrateur de votre organisation ou votre représentant des ventes pour obtenir une nouvelle invitation.",
    },
    map: 'carte',
    mapPage: 'Page de carte',
    hi: 'Bonjour',
    login: 'Connexion',
    LogIn: 'Connexion',
    logout: 'Déconnexion',
    No: 'Non',
    PasswordResetProblem: "Erreur - Le mot de passe n'a pas été réinitialisé.",
    poweredBy: 'Propulsé par',
    SendResetLink: ' Send reset link',
    SendEmail: 'Envoyer le courriel',
    siteDetails: 'Details du site',
    ResetPassword: 'Réinitialiser le mot de passe',
    ShareView: {
      LabelEmailAddress: 'Email address of the recipient',
      LabelMessage: 'Message (optional)',
      title: 'Partager la vue',
      overview: 'Map View',
      Share: 'Share',
      ShareLinkWarning: 'Share the link of this view (only authorised users will be able to see this view in Spectra)',
      warning: 'You are about to send this view to someone, please be sure not to share secure information.',
    },
    timelines: 'Lignes de temps',
    ThereIsNotEnoughDataInChart: "Il n'y a pas assez de donnée pour afficher ce graphique",
    Yes: 'Oui',
    YourPasswordsDoNotMatch: 'Vos mots de passe ne correspondent pas.',
  },
});
