/**
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_NEWS_URL } from '../../../core/constants/Constants';
import { AppContext } from '../../../core/context/app.context';
import { CMapImage } from '../../../core/interfaces/Common.interfaces';
import { Locales } from '../../../core/language/Localize';
import {
  clearLatestOpenArticleId,
  closeArticle,
  getLatestOpenArticleId,
  getNews,
  getOpenArticles,
  getReadArticles,
  openArticle,
  showArticlePlume,
} from '../../../core/redux/news.slice';
import { seeNewsPlumeOnMap } from '../../../core/utils/Map.utils';
import { ReactComponent as BlueAlert } from '../../assets/icons/blue-dot.svg';
import { ReactComponent as ChevronDownSvg } from '../../assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUpSvg } from '../../assets/icons/chevron-up.svg';
import CMapModal from './CMapModal';
import './NewsPanel.scss';

const NewsPanel = () => {
  const { mapView } = useContext(AppContext);
  const news = useSelector(getNews);
  const openArticleIds = useSelector(getOpenArticles);
  const latestOpenArticleId = useSelector(getLatestOpenArticleId);
  const readArticleIds = useSelector(getReadArticles);
  const dispatch = useDispatch();
  const scrollingRef = useRef<HTMLDivElement>(null);
  const [imageModal, setImageModal] = useState<CMapImage | undefined>(undefined);

  function getAccordionHeaderId(id: string) {
    return `g-article-header-${id}`;
  }

  function checkArrayForMissingValue<T>(arr1: T[], arr2: T[]): T | undefined {
    const [missingValue] = arr1.filter((x) => !arr2.includes(x));
    if (missingValue !== undefined) {
      return missingValue;
    }
  }

  function getDifferenceBetweenIndexes(arr1: number[], arr2: number[]): number | undefined {
    const indexRemoved = checkArrayForMissingValue(arr1, arr2);
    if (indexRemoved !== undefined) {
      return indexRemoved;
    }
    const indexAdded = checkArrayForMissingValue(arr2, arr1);
    if (indexAdded !== undefined) {
      return indexAdded;
    }
    return undefined;
  }

  const activeNewsIndexes = news
    .map((article, index) => (openArticleIds.includes(article.id) ? index : null))
    .filter((index): index is number => index !== null);

  const handleArticleChange = (newIndexes: number[]) => {
    const index = getDifferenceBetweenIndexes(activeNewsIndexes, newIndexes);
    if (index !== undefined) {
      const article = news[index];
      if (article) {
        if (openArticleIds.includes(article.id)) {
          dispatch(closeArticle(article.id));
        } else {
          dispatch(openArticle(article.id));
        }
      }
    }
  };
  useEffect(() => {
    if (latestOpenArticleId !== undefined && scrollingRef.current) {
      const scrollingArea = scrollingRef.current.getBoundingClientRect();
      const accordion = document.getElementById('g-news-accordion')?.getBoundingClientRect();
      const accordionTab = document.getElementById(getAccordionHeaderId(latestOpenArticleId))?.getBoundingClientRect();
      if (scrollingArea && accordion && accordionTab) {
        const yLocation = -accordion.top + accordionTab.top;
        scrollingRef.current.scrollTo({ top: yLocation, behavior: 'smooth' });
        dispatch(clearLatestOpenArticleId());
      }
    }
  }, [latestOpenArticleId, dispatch]);

  const handleImageClick = (imageUrl: string | undefined, imageTitle: string) => {
    if (imageUrl !== undefined) {
      setImageModal({ url: imageUrl, title: imageTitle });
    }
  };

  return (
    <div className="g-NewsPanel">
      <section className="g-NewsPanel-Section">
        <div className="g-NewsPanel-Articles" ref={scrollingRef}>
          <Accordion
            activeIndex={activeNewsIndexes}
            multiple
            expandIcon={<ChevronDownSvg />}
            collapseIcon={<ChevronUpSvg />}
            onTabChange={(e: any) => handleArticleChange(e.index)}
            id="g-news-accordion"
          >
            {news.map((article) => {
              const { id, latitude, longitude } = article;
              const read = readArticleIds.includes(id);

              const handleSeeOnMap = () => {
                dispatch(showArticlePlume(article.id));
                seeNewsPlumeOnMap(mapView, latitude, longitude);
              };

              return (
                <AccordionTab
                  key={article.id}
                  // AccordionTab accepts any properties and attaches them to the tab element
                  // but the types don't support this
                  //@ts-ignore
                  id={getAccordionHeaderId(article.id)}
                  header={
                    <>
                      {!read && <BlueAlert className="blue-dot" />}
                      {article.title}
                    </>
                  }
                >
                  {latitude !== undefined && longitude !== undefined && (
                    <Button
                      label={Locales.SeeOnMap}
                      className="p-button-primary p-button-outlined accordion-button"
                      onClick={handleSeeOnMap}
                    />
                  )}
                  <div className="accordion-summary-wrapper">
                    <div className="accordion-text">{article.mainContent}</div>
                    {article.CMAPimageUrl !== undefined && (
                      <img
                        src={BASE_NEWS_URL + article.CMAPimageUrl}
                        alt={article.title}
                        onClick={() => handleImageClick(article.CMAPimageUrl, article.title)}
                      />
                    )}
                  </div>
                  {article.linkUrl !== undefined && (
                    <a className="accordion-info-link" href={article.linkUrl} target="_blank" rel="noopener noreferrer">
                      {Locales.MoreInfo}
                    </a>
                  )}
                </AccordionTab>
              );
            })}
          </Accordion>
          {imageModal !== undefined && (
            <CMapModal setOpen={setImageModal} imageUrl={imageModal.url} imageTitle={imageModal.title} />
          )}
        </div>
      </section>
    </div>
  );
};
export default NewsPanel;
