/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import React, { ReactNode } from 'react';
import { Locales } from '../../../../core/language/Localize';
import { Dropdown } from 'primereact/dropdown';
import './TextSelect.scss';
import { ReactComponent as ChevronDownSvg } from '../../../assets/icons/chevron-down.svg';

interface LocalProps {
  id: string;
  value: string;
  options: string[];
  label?: string;
  name?: string;
  helpText?: string;
  className?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  noPlaceholder?: boolean;
  hideInput?: boolean;
  error?: boolean;
  disabled?: boolean;
  valid?: boolean;
  altered?: boolean;
  size?: 'sm' | 'md' | 'max';
  onChange: (newValue: string) => void;
}

const TextSelect = ({
  id,
  value,
  options,
  label,
  name,
  helpText,
  className,
  style,
  placeholder,
  noPlaceholder,
  error,
  disabled,
  valid,
  altered,
  size,
  onChange,
}: LocalProps) => {
  const primeReactTypedOptions = [
    {
      label: Locales.PleaseSelect,
      value: '',
      disabled: true,
    },
    ...options.map((value) => ({ label: value, value })),
  ];

  return (
    <div
      style={style}
      className={`field g-select-text ${className ? className : ''} ${altered ? 'altered' : ''} ${
        disabled ? 'disabled' : ''
      }  ${valid ? 'valid' : ''} ${error ? 'error' : ''} ${size ? size : ''}`}
    >
      {label && (
        <label id={`${id}-label`} htmlFor={id} className="block g-label">
          {label}
        </label>
      )}
      <Dropdown
        id={id}
        name={name}
        options={primeReactTypedOptions}
        className={`block g-select right-icon`}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        disabled={disabled}
        placeholder={noPlaceholder ? '' : placeholder ? placeholder : Locales.PleaseEnter}
        aria-invalid={value === ''}
        aria-describedby={`${id}-label`}
      />
      <span className="g-field-end-icon">
        <ChevronDownSvg />
      </span>
      {helpText && (
        <small id={`${id}-help`} className="help-text">
          {helpText}
        </small>
      )}
    </div>
  );
};

export default TextSelect;
