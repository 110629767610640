/*
 * Copyright 2021 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 * ----------------------------
 * This file contains both enums and functions that allow interaction
 * with the enums
 */
/** @format */

// Corresponds to https://developers.arcgis.com/javascript/latest/api-reference/esri-Map.html#basemap
export enum BASEMAPS {
  TOPO = 'topo-vector',
  SATELLITE = 'satellite',
  HYBRID = 'hybrid',
}

export enum E_UI_SETTINGS {
  Map = 'map',
  Table = 'table',
}

export enum E_LEFT_PANEL {
  Search = 'search',
  News = 'news',
  Insights = 'insights',
  Notifications = 'notifications',
  Basemap = 'basemap',
  Datalayers = 'datalayers',
}

export enum E_BOTTOM_PANEL {
  Timeline = 'timeline',
  Legend = 'legend',
  Display = 'display',
}
export enum E_RIGHT_PANEL {
  ViewHistory = 'viewHistory',
}

export enum TIMELINE_DATE {
  Start,
  End,
}

export enum LANG {
  en = 'en',
  fr = 'fr',
}

/**
 * Enum of layer ids where a single map service exists
 */
export enum LAYERS {
  Catalog = 'catalog',
  Grid = 'grid',
  GridHighlight = 'gridHighlight',
  Hotspot = 'hotspot',
  HotspotCluster = 'hotspotCluster',
  Predictions = 'predictions',
  Flares = 'flares',
  FlaresCluster = 'flaresCluster',
  Landmarks = 'landmarks',
  LandmarksCluster = 'landmarksCluster',
  MyBookmarks = 'mybookmarks',
  Site = 'site',
  SiteFavorite = 'siteFavorite',
  SitesCluster = 'sitesCluster',
  UserServiceZones = 'UserServiceZones',
  OtherZones = 'otherZones',
  HiddenZones = 'hiddenZones',
  Observations = 'observations',
  GhgPlumePins = 'ghgPlumePins',
  GhgPlumePinsCluster = 'ghgPlumePinsCluster',
  GhgPlumeRasters = 'ghgPlumeRasters',
  GhgPlumePinsAV = 'ghgPlumePinsAV',
  GhgPlumePinsClusterAV = 'ghgPlumePinsClusterAV',
  GhgPlumeRastersAV = 'ghgPlumeRastersAV',
  ThirdPartyPlumePins = 'thirdPartyPlumePins',
  ThirdPartyPlumePinsCluster = 'thirdPartyPlumePinsCluster',
  ThirdPartyPlumeRasters = 'thirdPartyPlumeRasters',
}

/**
 * Enum of layer ids where a series of map service for the same layer exist
 */
export enum BUNDLE_LAYERS {
  Concentration = 'concentration',
  GhgPlumePinsZone = 'ghgPlumePinsZone',
  GhgPlumeRasterZone = 'ghgPlumeRasterZone',
  GhgPlumePinsZoneCluster = 'ghgPlumePinsZoneCluster',
  ThirdPartyPlumePinsZone = 'thirdPartyPlumePinsZone',
  ThirdPartyPlumeRasterZone = 'thirdPartyPlumeRasterZone',
  ThirdPartyPlumePinsZoneCluster = 'thirdPartyPlumePinsZoneCluster',
}

/**
 * Enum of layer ids where LAYERS and BUNDLE_LAYERS are combined into an __esri.GroupLayer
 */
export enum GROUP_LAYERS {
  Concentration = 'concentrationGroup',
  Zones = 'zonesGroup',
  Hotspot = 'hotspotGroup',
  Flares = 'flaresGroup',
  Predictions = 'predictionsGroup',
  Observations = 'observationGroup',
  GhgPlumes = 'ghgPlumesGroup',
  GhgPlumesAV = 'ghgPlumesAVGroup',
  ThirdPartyPlumes = 'thirdPartyPlumesGroup',
  Plumes = 'plumesGroup',
  GhgPlumesZone = 'ghgPlumesZoneGroup',
  ThirdPartyPlumesZone = 'thirdPartyPlumesZoneGroup',
  PlumesZone = 'plumesZoneGroup',
  Sites = 'sitesGroup',
  Landmarks = 'landmarksGroup',
  Grid = 'gridGroup',
}

export enum LEAFLET_BASEMAPS {
  Topographic = 'esriTopographicBasemap',
  Satellite = 'esriSatelliteBasemap',
}

export enum LEAFLET_LAYERS {
  Concentration = 'concentration',
  NewsPlumesRaster = 'newsPlumesRaster',
  NewsPlumesPins = 'newsPlumesPins',
}

export enum LEAFLET_GROUP_LAYERS {
  Basemap = 'basemapGroup',
  Concentration = 'concentrationGroup',
  NewsPlumes = 'newsPlumesGroup',
}

export enum LEAFLET_PANES {
  Base = 'base',
  Raster = 'raster',
  PlumesRaster = 'plumesRaster',
  PlumesPins = 'plumesPins',
  Top = 'top',
}

export enum SENSOR_VALUES {
  S2 = 'sentinel2',
  S3 = 'sentinel3',
  S5 = 'sentinel5p',
}

/**
 * Search through a given enum for a target value. Can handle undefined and null values
 * @param value The value that will be the target to search for
 * @param enumerator The enum that will be searched through
 */
export function isInEnum(value: string | number, enumerator: Record<string, any>): boolean {
  if (!value) {
    return false;
  }
  return Object.values(enumerator).includes(value);
}
