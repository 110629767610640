/**
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { useEffect, useState } from 'react';
import { Locales } from '../../../core/language/Localize';
import pdf from '../../assets/pdfs/Terms of Use.pdf';
import './Footer.scss';

const appVersion = process.env.REACT_APP_VERSION;

interface LocalProps {
  normalWidth?: boolean;
}

const Footer = ({ normalWidth }: LocalProps) => {
  const [attribution, setAttribution] = useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchBasemapAttribution = async (): Promise<void> => {
      // Basemap metadata will need to be fetched manually from arcgisonline
      // since their api is not transparent in terms of attribution
      const response = await fetch(
        'https://server.arcgisonline.com/arcgis/rest/services/World_Topo_Map/MapServer?f=json',
      );
      const jsonResponse = await response.json();
      if (jsonResponse.copyrightText) {
        setAttribution(jsonResponse.copyrightText);
      }
    };
    void fetchBasemapAttribution();
  }, []);

  return (
    <footer className="g-Footer" style={normalWidth ? {} : { width: '100vw' }}>
      <div className="g-attribution-container">
        <div className="g-attribution">
          <p className="g-powered-by">V {appVersion}</p>
        </div>
        <div className="right">
          <a target="_blank" rel="noreferrer" className="g-link terms-of-use" href={pdf}>
            {Locales.TermsofUse}
          </a>
          <span className="spacer">|</span>
          <a target="_blank" rel="noreferrer" className="g-link terms-of-use" href="http://leafletjs.com/">
            {Locales.Leaflet}
          </a>
          <span className="spacer">|</span>
          <p className="g-powered-by">
            {Locales.poweredBy}{' '}
            <a target="_blank" rel="noreferrer" className="g-link terms-of-use" href="https://www.esri.com/">
              {Locales.Esri}
            </a>
          </p>
          <span className="spacer">|</span>
          <p className="g-powered-by">
            <a target="_blank" rel="noreferrer" className="g-link terms-of-use" href={Locales.ghgSatUrl}>
              {Locales.ghgSat}
            </a>
          </p>
          <span className="spacer">|</span>
        </div>
        <div className="g-attribution">
          <p className="g-powered-by">{attribution}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
