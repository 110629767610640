/**
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { sendCompletedFormToHubspot } from '../../../api/hubspotApi';
import { ToastParams } from '../../../core/interfaces/Common.interfaces';
import { Locales } from '../../../core/language/Localize';
import { getUser } from '../../../core/redux/user.slice';
import ModalBody from '../Reusable/ModalWindow/ModalBody';
import ModalFooterButtons from '../Reusable/ModalWindow/ModalFooterButtons';
import ModalWindow from '../Reusable/ModalWindow/ModalWindow';
import TextButton from '../Reusable/TextButton/TextButton';
import TextField, { AUTOCOMPLETE } from '../Reusable/TextField/TextField';
import TextSelect from '../Reusable/TextSelect/TextSelect';
import './UpgradeFormModal.scss';

interface LocalProps {
  open: boolean;
  setOpen: (visibility: boolean) => void;
}

const UpgradeFormModal = ({ open, setOpen }: LocalProps) => {
  const user = useSelector(getUser);
  const [email, setEmail] = useState<string>(user.email);
  const [organization, setOrganization] = useState<string>(user.orgBasic ?? '');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [lookingFor, setLookingFor] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [toast, setToast] = useState<ToastParams | undefined>(undefined);

  const lookingForOptions = [
    'Details on SPECTRA Premium & Add-On Features',
    'SPECTRA Premium & Add-On Pricing',
    'Demo of SPECTRA Premium',
  ];

  const isValid = email !== '' && organization !== '' && phoneNumber !== '' && lookingFor !== '';

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isValid) {
      setError('');
      setLoading(true);
      const body = {
        fields: [
          {
            name: 'email',
            value: email,
          },
          {
            name: 'company',
            value: organization,
          },
          {
            name: 'phone',
            value: phoneNumber,
          },
          {
            name: 'what_information_are_you_looking_for_',
            value: lookingFor,
          },
        ],
      };
      try {
        const isValidEmail = await sendCompletedFormToHubspot(body);
        if (isValidEmail) {
          setToast({
            severity: 'success',
            summary: 'Submission Successful',
            detail: Locales.UpgradeForm.SubmissionSuccessful,
          });
        } else {
          setError(Locales.UpgradeForm.SubmissionEmailBlocked);
        }
      } catch (e: any) {
        if (e.message) {
          console.error(e.message);
        }
        setError(Locales.UpgradeForm.SubmissionUnsuccessful);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <ModalWindow
      title={Locales.UpgradeNow}
      open={open}
      size="md"
      toast={toast}
      clearToast={() => setToast(undefined)}
      onCancelClick={() => setOpen(false)}
    >
      <form onSubmit={handleSubmit}>
        <ModalBody className="g-upgrade-form-container">
          <div className="g-upgrade-form-info-container">
            <h4>{Locales.UpgradeForm?.UpgradeToSpectraPremium}</h4>
            <h4>{Locales.UpgradeForm?.WithSpectraPremium}</h4>
            <ul>
              <li>{Locales.UpgradeForm?.Info1}</li>
              <li>{Locales.UpgradeForm?.Info2}</li>
              <li>{Locales.UpgradeForm?.Info3}</li>
            </ul>
            <div className="g-upgrade-form-additional-info">
              <p>* Mandatory Fields</p>
              <p className="error">{error}</p>
            </div>
          </div>
          <div className="g-upgrade-form">
            <TextField
              id="upgrade-email"
              name="email"
              type="email"
              autocomplete={AUTOCOMPLETE.EMAIL}
              className="g-upgrade-input"
              label={Locales.Email + '*'}
              placeholder={Locales.Email}
              value={email}
              onChange={setEmail}
              required
            />
            <TextField
              id="upgrade-organization"
              name="organization"
              type="organization"
              autocomplete={AUTOCOMPLETE.ORGANIZATION}
              className="g-upgrade-input"
              label={Locales.Organization + '*'}
              placeholder={Locales.Organization}
              value={organization}
              onChange={setOrganization}
              required
            />
            <TextField
              id="upgrade-phone"
              name="phone"
              type="phone"
              autocomplete={AUTOCOMPLETE.PHONE}
              className="g-upgrade-input"
              label={Locales.PhoneNumber + '*'}
              placeholder={Locales.PhoneNumber}
              value={phoneNumber}
              onChange={setPhoneNumber}
              required
            />
            <TextSelect
              id="upgrade-looking-for"
              name="looking-for"
              options={lookingForOptions}
              label={Locales.WhatInformationAreYouLookingFor + '*'}
              placeholder={Locales.WhatInformationAreYouLookingFor}
              className={'g-select-field drop-down g-upgrade-input'}
              value={lookingFor}
              onChange={setLookingFor}
            />
          </div>
        </ModalBody>
        <ModalFooterButtons>
          <TextButton type="outlined" onClick={() => setOpen(false)}>
            {Locales.Close}
          </TextButton>
          <TextButton type="filled" loading={loading} disabled={!isValid} submit>
            {Locales.RequestMoreInformation}
          </TextButton>
        </ModalFooterButtons>
      </form>
    </ModalWindow>
  );
};
export default UpgradeFormModal;
