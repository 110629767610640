/**
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Locales } from '../../../core/language/Localize';
import { getUser, setIsAuthenticated } from '../../../core/redux/user.slice';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { ReactComponent as SpectraLogo } from '../../assets/icons/logo-spectra-white.svg';
import { ReactComponent as HelpIcon } from '../../assets/icons/help.svg';
import { ReactComponent as AvatarIcon } from '../../assets/icons/avatar.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import './TopBar.scss';
import MyProfileModal from '../MyProfile/MyProfileModal';
import IconButton from '../Reusable/IconButton/IconButton';
import FAQModal from '../FAQModal/FAQModal';
import ShareViewModal from '../ShareView/ShareViewModal';
import { signOut } from '../../../api/awsAuthApi';
import AlertBanner from '../Reusable/AlertBanner/AlertBanner';

const TopBar = () => {
  const [myProfileModalVisibility, setMyProfileModalVisibility] = useState<boolean>(false);
  const [faqModalWindowVisibility, setFaqModalWindowVisibility] = useState<boolean>(false);
  const [shareModalVisibility, setShareModalVisibility] = useState<boolean>(false);
  const [alertBannerVisibility, setAlertBannerVisibility] = useState<boolean>(true);
  const user = useSelector(getUser);
  const dispatch = useDispatch();

  let ddMenu: any = useRef(null);

  const items = [
    {
      disabled: true,
      label: `${Locales.MyProfile}`,
      command: () => {
        setMyProfileModalVisibility(true);
      },
    },
    {
      disabled: true,
      label: `${Locales.UserManagement}`,
    },
    {
      disabled: true,
      label: `${Locales.MyBookmarks}`,
    },
    {
      disabled: true,
      label: `${Locales.Reports}`,
    },
    {
      disabled: true,
      label: `${Locales.Settings}`,
    },
    {
      label: <span className="color-red">{Locales.Disconnect}</span>,
      command: async () => {
        try {
          await signOut();
          dispatch(setIsAuthenticated(false));
        } catch (e: any) {
          console.log(e.message);
        }
      },
    },
  ];

  const username = !!user.firstName ? `${user.firstName} ${user.lastName}` : user.name;
  const organization = user.orgBasic ?? '';
  return (
    <header id="masthead" className="g-Top-Appbar" style={{ width: '100vw' }}>
      <div id="left-menu" className="g-Top-Appbar-Section--left">
        <div className="g-Top-Appbar-Logo-container">
          <a href="/" title={Locales.ghgSat}>
            <SpectraLogo width={167} height={22} />
          </a>
        </div>
      </div>
      <div id="right-menu" className="right g-Top-Appbar-Section--right">
        <div className="g-Top-Appbar-Section--help">
          <IconButton ariaLabel="Help" className="g-icon-help" onClick={() => setFaqModalWindowVisibility(true)}>
            <HelpIcon />
          </IconButton>
        </div>

        <div className="g-Top-Appbar-Section--profile" id="profile-container">
          <div className="user-avatar">
            <AvatarIcon />
          </div>
          <div className="g-Top-Appbar-Section--Profile-info-wrapper">
            <p className="g-Top-Appbar-Section--Profile-info">
              <span className="Profile-info-username limit-size">{username}</span>
            </p>
            <p className="g-Top-Appbar-Section--Profile-info limit-size">
              <span>{organization}</span>
            </p>
          </div>
          <Menu
            // @ts-ignore
            model={items}
            popup
            ref={(el) => (ddMenu = el)}
            id="popup_menu"
            className="g-Menu-Popup theme-dark"
            appendTo={document.getElementById('right-menu')}
          />
          <Button
            icon={<ArrowIcon className="g-arrow" />}
            className="p-button-rounded p-button-text color surface-a user-menu-button"
            id="top-bar-menu"
            onClick={(event) => ddMenu.toggle(event)}
            aria-controls="popup_menu"
            aria-haspopup
          />
          {myProfileModalVisibility && (
            <MyProfileModal open={myProfileModalVisibility} setOpen={setMyProfileModalVisibility} />
          )}
          {faqModalWindowVisibility && (
            <FAQModal open={faqModalWindowVisibility} setOpen={setFaqModalWindowVisibility} />
          )}
          {shareModalVisibility && (
            <ShareViewModal visibility={shareModalVisibility} setVisibility={setShareModalVisibility} />
          )}
        </div>
      </div>
      {alertBannerVisibility && (
        <AlertBanner
          description={Locales.UpgradeForm.UpgradeToSpectraPremium}
          icon="info"
          setOpen={setAlertBannerVisibility}
          cancelButton={true}
          colour={'blue'}
          actionButtonUrl={'https://www.ghgsat.com/en/demo/'}
          actionButtonText={Locales.UpgradeNow}
        />
      )}
    </header>
  );
};

export default TopBar;
