/**
 * /*
 * Copyright 2021 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 * @format
 */

import { Locales } from '../../../core/language/Localize';
import { ReactComponent as AircraftIcon } from '../../assets/icons/legend/aircraft.svg';
import { ReactComponent as GhgsatEmissionIcon } from '../../assets/icons/legend/emission-ghgsat.svg';
import { ReactComponent as ThirdPartyEmissionIcon } from '../../assets/icons/legend/emission-third-party.svg';
import { ReactComponent as FlareIcon } from '../../assets/icons/legend/flare.svg';
import { ReactComponent as HotspotIcon } from '../../assets/icons/legend/hotspot.svg';
import { ReactComponent as LandmarkIcon } from '../../assets/icons/legend/landmark.svg';
import { ReactComponent as ObservationPurchased } from '../../assets/icons/legend/observation-full.svg';
import { ReactComponent as ObservationAvailable } from '../../assets/icons/legend/observation-stroke.svg';
import { ReactComponent as SatelliteIcon } from '../../assets/icons/legend/satellite.svg';
import { ReactComponent as AgricultureIcon } from '../../assets/icons/legend/site-agriculture.svg';
import { ReactComponent as MidstreamIcon } from '../../assets/icons/legend/site-midstream.svg';
import { ReactComponent as MiningIcon } from '../../assets/icons/legend/site-mining.svg';
import { ReactComponent as OtherIcon } from '../../assets/icons/legend/site-other.svg';
import { ReactComponent as PowerIcon } from '../../assets/icons/legend/site-power.svg';
import { ReactComponent as TargetIcon } from '../../assets/icons/legend/site-target.svg';
import { ReactComponent as WasteIcon } from '../../assets/icons/legend/site-waste.svg';
import { ReactComponent as WellIcon } from '../../assets/icons/legend/site-well.svg';
import { ReactComponent as StatusFlareIcon } from '../../assets/icons/legend/status-flare.svg';
import { ReactComponent as StatusMaybeIcon } from '../../assets/icons/legend/status-maybe.svg';
import { ReactComponent as StatusNoDataIcon } from '../../assets/icons/legend/status-no-data.svg';
import { ReactComponent as StatusNoIcon } from '../../assets/icons/legend/status-no.svg';
import { ReactComponent as StatusYesIcon } from '../../assets/icons/legend/status-yes.svg';
import './Legend.scss';

export const Legend = () => {
  return (
    <div className="g-Legend-Container">
      <div className="g-Legend" id="legend">
        <div className="layers">
          <div className="layer">
            <header className="header">
              <h4 className="title">{Locales.SiteType}</h4>
            </header>
            <ul className="items multi-cols">
              <li className="item-col">
                <ul>
                  <li className="item">
                    <div className="icon-container">
                      <WellIcon className="icon" title={Locales.OilGasWellsSite} />
                    </div>
                    <span className="label">{Locales.OilGasWellsSite}</span>
                  </li>
                  <li className="item">
                    <div className="icon-container">
                      <MidstreamIcon className="icon" title={Locales.OilGasMidStream} />
                    </div>
                    <span className="label">{Locales.OilGasMidStream}</span>
                  </li>
                  <li className="item">
                    <div className="icon-container">
                      <MiningIcon className="icon" title={Locales.Mining} />
                    </div>
                    <span className="label">{Locales.Mining}</span>
                  </li>
                  <li className="item">
                    <div className="icon-container">
                      <AgricultureIcon className="icon" title={Locales.Agriculture} />
                    </div>
                    <span className="label">{Locales.Agriculture}</span>
                  </li>
                </ul>
              </li>
              <li className="item-col">
                <ul>
                  <li className="item">
                    <div className="icon-container">
                      <PowerIcon className="icon" title={Locales.PowerGeneration} />
                    </div>
                    <span className="label">{Locales.PowerGeneration}</span>
                  </li>
                  <li className="item">
                    <div className="icon-container">
                      <WasteIcon className="icon" title={Locales.WasteManagement} />
                    </div>
                    <span className="label">{Locales.WasteManagement}</span>
                  </li>
                  <li className="item">
                    <div className="icon-container">
                      <TargetIcon className="icon" title={Locales.SatelliteTarget} />
                    </div>
                    <span className="label">{Locales.SatelliteTarget}</span>
                  </li>
                  <li className="item">
                    <div className="icon-container">
                      <OtherIcon className="icon" title={Locales.SatelliteTarget} />
                    </div>
                    <span className="label">{Locales.Other}</span>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="layer">
            <header className="header">
              <h4 className="title">{Locales.Status}</h4>
            </header>
            <ul className="items">
              <li className="item">
                <div className="icon-container">
                  <StatusFlareIcon className="icon" title={Locales.FlaresDetected} />
                </div>
                <span className="label">{Locales.FlaresDetected}</span>
              </li>
              <li className="item">
                <div className="icon-container">
                  <StatusNoIcon className="icon small" title={Locales.NoEmissions} />
                </div>
                <span className="label">{Locales.NoEmissions}</span>
              </li>
              <li className="item">
                <div className="icon-container">
                  <StatusMaybeIcon className="icon small" title={Locales.PossibleEmissons} />
                </div>
                <span className="label">{Locales.PossibleEmissons}</span>
              </li>
              <li className="item">
                <div className="icon-container">
                  <StatusYesIcon className="icon small" title={Locales.EmissionsDetected} />
                </div>
                <span className="label">{Locales.EmissionsDetected}</span>
              </li>
              <li className="item">
                <div className="icon-container">
                  <StatusNoDataIcon className="icon small" title={Locales.NoData} />
                </div>
                <span className="label">{Locales.NoData}</span>
              </li>
            </ul>
          </div>
          <div className="header">
            <header className="header">
              <h4 className="title">{Locales.Zones}</h4>
            </header>
            <ul className="items">
              <li className="item">
                <div className="icon-container">
                  <i className="icon icon-zone-square analytics" />
                </div>
                <span className="label">{Locales.UserZones}</span>
              </li>
              <li className="item">
                <div className="icon-container">
                  <i className="icon icon-zone-square other" />
                </div>
                <span className="label">{Locales.OtherZones}</span>
              </li>
            </ul>
          </div>
          <div className="layer">
            <header className="header">
              <h4 className="title">{Locales.Emissions}</h4>
            </header>
            <ul className="items multi-cols">
              <li className="emissions-scale item-col">
                <div className="emission-scale-gradient" />
                <div className="emission-scale-labels">
                  <span className="label">{Locales.High}</span>
                  <span className="label">{Locales.Low}</span>
                </div>
              </li>
              <li className="item-col">
                <ul>
                  <li className="item">
                    <span className="label">{Locales.Source}</span>
                  </li>
                  <li className="item">
                    <div className="icon-container">
                      <GhgsatEmissionIcon className="icon large" title={Locales.GHGSatSensor} />
                    </div>
                    <span className="label">{Locales.GHGSatSensor}</span>
                  </li>
                  <li className="item">
                    <div className="icon-container">
                      <ThirdPartyEmissionIcon className="icon large" title={Locales.ThirdPartySensor} />
                    </div>
                    <span className="label">{Locales.ThirdPartySensor}</span>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="layer">
            <header className="header">
              <h4 className="title">{Locales.Observations}</h4>
            </header>
            <ul className="items">
              <li className="item">
                <div className="icon-container">
                  <ObservationPurchased title={Locales.AvailableObservation} />
                </div>
                <span className="label">{Locales.AvailableObservation}</span>
              </li>
              <li className="item">
                <div className="icon-container">
                  <ObservationPurchased className="observation-teal" title={Locales.CatalogObservation} />
                </div>
                <span className="label">{Locales.CatalogObservation}</span>
              </li>
              <li className="item">
                <div className="icon-container">
                  <ObservationAvailable title={Locales.CatalogOffering} />
                </div>
                <span className="label">{Locales.CatalogOffering}</span>
              </li>
            </ul>
          </div>
          <div className="layer">
            <header className="header">
              <h4 className="title">{Locales.Concentration}</h4>
            </header>
            <ul className="items">
              <li className="item">
                <div className="icon-container">
                  <i className="icon icon-g-concentration-square l-1" />
                </div>
                <span className="label">≥ 1,930 ppb</span>
              </li>
              <li className="item">
                <div className="icon-container">
                  <i className="icon icon-g-concentration-square l-2" />
                </div>
                <span className="label">&lt; 1930 ppb</span>
              </li>
              <li className="item">
                <div className="icon-container">
                  <i className="icon icon-g-concentration-square l-3" />
                </div>
                <span className="label">&lt; 1920 ppb</span>
              </li>
              <li className="item">
                <div className="icon-container">
                  <i className="icon icon-g-concentration-square l-4" />
                </div>
                <span className="label">&lt; 1910 ppb</span>
              </li>
              <li className="item">
                <div className="icon-container">
                  <i className="icon icon-g-concentration-square l-5" />
                </div>
                <span className="label">&lt; 1900 ppb</span>
              </li>
              <li className="item">
                <div className="icon-container">
                  <i className="icon icon-g-concentration-square l-6" />
                </div>
                <span className="label">&lt; 1890 ppb</span>
              </li>
            </ul>
          </div>
          <div className="layer">
            <header className="header">
              <h4 className="title">{Locales.Prediction}</h4>
            </header>
            <ul className="items">
              <li className="item">
                <div className="icon-container">
                  <i className="icon icon-g-prediction-square l-1" />
                </div>
                <span className="label">81-99%</span>
              </li>
              <li className="item">
                <div className="icon-container">
                  <i className="icon icon-g-prediction-square l-2" />
                </div>
                <span className="label">61-80%</span>
              </li>
              <li className="item">
                <div className="icon-container">
                  <i className="icon icon-g-prediction-square l-3" />
                </div>
                <span className="label">41-60%</span>
              </li>
              <li className="item">
                <div className="icon-container">
                  <i className="icon icon-g-prediction-square l-4" />
                </div>
                <span className="label">21-40%</span>
              </li>
              <li className="item">
                <div className="icon-container">
                  <i className="icon icon-g-prediction-square l-5" />
                </div>
                <span className="label">1-20%</span>
              </li>
            </ul>
          </div>
          <div className="layer">
            <header className="header">
              <h4 className="title">{Locales.Other}</h4>
            </header>
            <ul className="items">
              <li className="item">
                <div className="icon-container">
                  <HotspotIcon className="icon" title={Locales.HotspotDetected} />
                </div>
                <span className="label">{Locales.HotspotDetected}</span>
              </li>
              <li className="item">
                <div className="icon-container">
                  <AircraftIcon className="icon" title={Locales.AircraftSensor} />
                </div>
                <span className="label">{Locales.AircraftSensor}</span>
              </li>
              <li className="item">
                <div className="icon-container">
                  <SatelliteIcon className="icon" title={Locales.SatelliteSensor} />
                </div>
                <span className="label">{Locales.SatelliteSensor}</span>
              </li>
              <li className="item">
                <div className="icon-container">
                  <FlareIcon className="icon small" title={Locales.Flare} />
                </div>
                <span className="label">{Locales.Flare}</span>
              </li>
              <li className="item">
                <div className="icon-container">
                  <LandmarkIcon className="icon" title={Locales.Landmark} />
                </div>
                <span className="label">{Locales.Landmark}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
