/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
import { useState } from 'react';
import { Locales } from '../../../../core/language/Localize';
import { ReactComponent as InfoSVG } from '../../../assets/icons/info.svg';
import { ReactComponent as TempCheckSVG } from '../../../assets/icons/temp_check.svg';
import UpgradeFormModal from '../../UpgradeFormModal/UpgradeFormModal';
import TextButton from '../TextButton/TextButton';
import './AlertBanner.scss';

interface LocalProps {
  title?: string;
  description: string;
  icon?: 'info' | 'check';
  moreInfoLink?: string;
  cancelButton?: boolean;
  setOpen: (visibility: boolean) => void;
  actionButtonUrl?: string;
  actionButtonText?: string;
  colour?: 'blue';
}

const AlertBanner = ({
  title,
  description,
  icon,
  moreInfoLink,
  cancelButton,
  setOpen,
  actionButtonUrl,
  actionButtonText,
  colour,
}: LocalProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <>
      <div className={`alertBanner ${colour ? colour : ''}`}>
        <div className="icon-div">{icon === 'info' ? <InfoSVG /> : <TempCheckSVG />}</div>
        <div className="title-div">
          {title && <p className="title">{title}</p>}
          <p> {description}</p>
        </div>
        <div className="action-button-div">
          {actionButtonUrl && actionButtonText && (
            <TextButton type="filled" style={{ textTransform: 'capitalize' }} onClick={() => setModalOpen(true)}>
              {actionButtonText}
            </TextButton>
          )}
        </div>
        <div className="cancel-more-info-div">
          {cancelButton && (
            <TextButton type="no-outline" style={{ textTransform: 'capitalize' }} onClick={() => setOpen(false)}>
              {Locales.Close}
            </TextButton>
          )}
          {moreInfoLink && (
            <TextButton
              type="link-styled"
              style={{ textTransform: 'capitalize' }}
              onClick={() => window.open(`${moreInfoLink},`, '_blank')}
            >
              {Locales.MoreInfo}
            </TextButton>
          )}
        </div>
      </div>
      {modalOpen && <UpgradeFormModal open={modalOpen} setOpen={setModalOpen} />}
    </>
  );
};

export default AlertBanner;
