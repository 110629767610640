/**
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { Locales } from '../../../core/language/Localize';
import ModalBody from '../Reusable/ModalWindow/ModalBody';
import ModalFooterButtons from '../Reusable/ModalWindow/ModalFooterButtons';
import ModalWindow from '../Reusable/ModalWindow/ModalWindow';
import TextButton from '../Reusable/TextButton/TextButton';
import './FAQModal.scss';

interface LocalProps {
  open: boolean;
  setOpen: (visibility: boolean) => void;
}

const FAQModal = ({ open, setOpen }: LocalProps) => {
  return (
    <ModalWindow title={Locales.FAQ?.Title} open={open} size="md">
      <ModalBody>
        <div className="QandA-div">
          <h4> {Locales.FAQ?.SpectraBasicByGHGSat}</h4>
          <p> {Locales.FAQ?.SubHeader}</p>
        </div>
        <div className="QandA-div">
          <h4> {Locales.FAQ?.HowToUseSpectraBasicQ} </h4>
          <p> {Locales.FAQ?.HowToUseSpectraBasicA}</p>
        </div>
        <div className="QandA-div">
          <h4> {Locales.FAQ?.WhatCanASpectraBasicUserAccessQ} </h4>
          <p> {Locales.FAQ?.WhatCanASpectraBasicUserAccessA}</p>
        </div>
        <div className="QandA-div">
          <h4> {Locales.FAQ?.WhatSpectraBasicCanShowYouQ} </h4>
          <p> {Locales.FAQ?.WhatSpectraBasicCanShowYouA}</p>
        </div>
        <div className="QandA-div">
          <h4> {Locales.FAQ?.CanIShareTheMapQ} </h4>
          <p> {Locales.FAQ?.CanIShareTheMapA}</p>
        </div>
        <div className="QandA-div">
          <h4> {Locales.FAQ?.WhatSpectraBasicCantTellYouQ} </h4>
          <p> {Locales.FAQ?.WhatSpectraBasicCantTellYouA1}</p>
          <br />
          <p>
            {' '}
            {Locales.FAQ?.WhatSpectraBasicCantTellYouA2} (
            <a href="https://amt.copernicus.org/articles/14/2127/2021/" target="_blank" rel="noopener noreferrer">
              https://amt.copernicus.org/articles/14/2127/2021/
            </a>
            ) {Locales.FAQ?.WhatSpectraBasicCantTellYouA3} (
            <a
              href="http://www.tropomi.eu/sites/default/files/files/publicSentinel-5P-TROPOMI-ATBD-Methane-retrieval.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://www.tropomi.eu/sites/default/files/files/publicSentinel-5P-TROPOMI-ATBD-Methane-retrieval.pdf
            </a>
            )
          </p>
        </div>
        <div className="QandA-div">
          <h4> {Locales.FAQ?.WhatDoesConcentrationsMeanQ} </h4>
          <p> {Locales.FAQ?.WhatDoesConcentrationsMeanA}</p>
        </div>
        <div className="QandA-div">
          <h4> {Locales.FAQ?.HowDoYouCalculateTheConcentrationLevelsQ} </h4>
          <p> {Locales.FAQ?.HowDoYouCalculateTheConcentrationLevelsA}</p>
        </div>
        <div className="QandA-div">
          <h4> {Locales.FAQ?.WhatDoesTheColourScaleMeanQ} </h4>
          <p> {Locales.FAQ?.WhatDoesTheColourScaleMeanA}</p>
        </div>
        <div className="QandA-div">
          <h4> {Locales.FAQ?.ConcernQ} </h4>
          <p> {Locales.FAQ?.ConcernA}</p>
        </div>
        <div className="QandA-div">
          <h4> {Locales.FAQ?.GreyOverLandQ} </h4>
          <p> {Locales.FAQ?.GreyOverLandA}</p>
        </div>
        <div className="QandA-div">
          <h4> {Locales.FAQ?.HowToUseSpectraBasicQ} </h4>
          <p> {Locales.FAQ?.HowToUseSpectraBasicA}</p>
        </div>
        <div className="QandA-div">
          <h4> {Locales.FAQ?.OceansQ} </h4>
          <p> {Locales.FAQ?.OceansA}</p>
        </div>
        <div className="QandA-div">
          <h4> {Locales.FAQ?.DataQ} </h4>
          <p> {Locales.FAQ?.DataA}</p>
        </div>
        <div className="QandA-div">
          <h4> {Locales.FAQ?.AccessDataQ} </h4>
          <p>
            {Locales.FAQ?.AccessDataA}{' '}
            <a href="https://www.ghgsat.com/en/products-services/" target="_blank" rel="noopener noreferrer">
              https://www.ghgsat.com/en/products-services/
            </a>
            .
          </p>
        </div>
        <div className="QandA-div">
          <h4> {Locales.FAQ?.UnderlyingDataQ} </h4>
          <p>
            {Locales.FAQ?.UnderlyingDataA1}{' '}
            <a href="mailto:info.spectrabasic@ghgsat.com">info.spectrabasic@ghgsat.com</a>{' '}
            {Locales.FAQ?.UnderlyingDataA2}
          </p>
        </div>
        <div className="QandA-div">
          <h4> {Locales.FAQ?.CommercialUseQ} </h4>
          <p>
            {Locales.FAQ?.CommercialUseA1}{' '}
            <a href="mailto:info.spectrabasic@ghgsat.com">info.spectrabasic@ghgsat.com</a>{' '}
            {Locales.FAQ?.CommercialUseA2}
          </p>
        </div>
        <div className="QandA-div">
          <h4> {Locales.FAQ?.WhyHaveYouBuiltSpectraBasicQ} </h4>
          <p> {Locales.FAQ?.WhyHaveYouBuiltSpectraBasicA}</p>
        </div>
      </ModalBody>
      <ModalFooterButtons>
        <TextButton type="filled" onClick={() => setOpen(false)}>
          {Locales.Close}
        </TextButton>
      </ModalFooterButtons>
    </ModalWindow>
  );
};
export default FAQModal;
