/**
 *
 * Copyright 2021 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 */

import { Button } from 'primereact/button';
import { GROUP_LAYERS, LAYERS, LEAFLET_GROUP_LAYERS } from '../../../core/constants/Enums';
import { Locales } from '../../../core/language/Localize';
import LayerListGroup from '../LayerList/LayerListGroup';
import './DataLayersPanel.scss';

const DataLayersPanel = () => {
  return (
    <div className="g-DataLayersPanel">
      <section className="g-WidgetPanel-Section with-border">
        <header className="g-WidgetPanel-Header">
          <h4 className="heading">{Locales.MyLayers}</h4>
        </header>
        <div className="tool-panel-content body">
          <LayerListGroup
            layerIds={[
              GROUP_LAYERS.Sites,
              LAYERS.SiteFavorite,
              GROUP_LAYERS.Zones,
              GROUP_LAYERS.Grid,
              LAYERS.MyBookmarks,
            ]}
            type="users"
          />
        </div>
        <Button
          id="addLayer"
          className="btn-reset-styles p-button-link btn-add-layer"
          label={Locales.AddALayer}
          title={Locales.AddALayer}
          disabled
        />
      </section>
      <section className="g-WidgetPanel-Section">
        <header className="g-WidgetPanel-Header">
          <h4 className="heading">{Locales.Methane}</h4>
        </header>
        <div className="tool-panel-content body">
          <LayerListGroup
            layerIds={[
              {
                id: GROUP_LAYERS.PlumesZone,
                children: [GROUP_LAYERS.GhgPlumesZone, GROUP_LAYERS.ThirdPartyPlumesZone],
              },
              LEAFLET_GROUP_LAYERS.Concentration,
              GROUP_LAYERS.Hotspot,
              GROUP_LAYERS.Flares,
              GROUP_LAYERS.Predictions,
              GROUP_LAYERS.Landmarks,
              {
                id: GROUP_LAYERS.Plumes,
                children: [GROUP_LAYERS.GhgPlumes, GROUP_LAYERS.GhgPlumesAV, GROUP_LAYERS.ThirdPartyPlumes],
              },
              GROUP_LAYERS.Observations,
              LAYERS.Catalog,
            ]}
            type="ghgsat"
          />
        </div>
      </section>
    </div>
  );
};

export default DataLayersPanel;
